import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import AirtableBase from "../modules/airtable";
import { Container, Grid, Paper, Typography, Box, TextField, Button } from "@material-ui/core";
import configurations from "../modules/configurations";

import validator from "validator";

export default () => {
  const history = useHistory();
  const { dispatch } = useContext(configurations);
  const Available = localStorage.getItem("isLoggedIn");

  const [Saved, setSaved] = useState(false);
  const [SaveId, setSaveId] = useState("");

  const [WordCount, setWordCount] = useState(0);

  const [Assessment, setAssessment] = useState({});

  const { assessment, student } = useParams();

  const [Answer, setAnswer] = useState("");
  const [ErrorAnswer, setErrorAnswer] = useState(false);

  useEffect(() => {
    if (!Available) history.push("/");
  }, [Available, history]);

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("assessments").find(assessment, function (err, record) {
      if (err) console.error(err);
      setAssessment(record.fields);
      dispatch({ type: "backdropclose" });
    });
  }, [assessment, dispatch]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("solutions")
      .select({ maxRecords: 1, filterByFormula: `AND({student id}='${student}', {assessment id}='${assessment}')` })
      .firstPage((err, records) => {
        const student = records[0].fields;
        if ("submission" in student) {
          dispatch({
            type: "snackbaropen",
            val_open: true,
            val_type: "error",
            val_message: "You have already submitted the assessment",
          });
          history.push("/dashboard");
        } else {
          const ans = student.answer;
          setAnswer(validator.unescape(ans));
          setSaved(true);
          setSaveId(records[0].id);
          setWordCount(ans.split(/\s+/).length - 1);
        }
        dispatch({ type: "backdropclose" });
      });
  }, [assessment, student, dispatch, history]);

  const HandleSave = (event) => {
    dispatch({ type: "backdropopen" });

    if (Saved === true) {
      AirtableBase("solutions").update(
        [
          {
            id: SaveId,
            fields: {
              answer: validator.escape(Answer),
            },
          },
        ],
        function (err, records) {
          if (err) console.error(err);
          dispatch({
            type: "snackbaropen",
            val_open: true,
            val_type: "success",
            val_message: "Your answer is saved in the system",
          });
          dispatch({ type: "backdropclose" });
        }
      );
    }

    if (Saved === false) {
      AirtableBase("solutions").create(
        [
          {
            fields: {
              answer: validator.escape(Answer),
              student: [student],
              assessment: [assessment],
            },
          },
        ],
        (err, records) => {
          if (err) console.error(err);
          records.forEach((record) => {
            setSaved(true);
            setSaveId(record.id);
            dispatch({
              type: "snackbaropen",
              val_open: true,
              val_type: "success",
              val_message: "Your answer is saved in the system",
            });
            dispatch({ type: "backdropclose" });
          });
        }
      );
    }
  };

  const HandleSubmit = (event) => {
    event.preventDefault();

    if (Answer === "") {
      setErrorAnswer(true);
    } else {
      const confirm = window.confirm("Are you sure you want to submit. You will not be able to submit your answer again.");
      if (confirm) {
        dispatch({ type: "backdropopen" });

        if (Saved === true) {
          AirtableBase("solutions").update(
            [
              {
                id: SaveId,
                fields: {
                  answer: validator.escape(Answer),
                  submission: true,
                },
              },
            ],
            function (err, records) {
              if (err) console.error(err);
              history.push("/dashboard");
              dispatch({
                type: "snackbaropen",
                val_open: true,
                val_type: "success",
                val_message: "Thank you for submitting the assessment",
              });
              dispatch({ type: "backdropclose" });
            }
          );
        }

        if (Saved === false) {
          AirtableBase("solutions").create(
            [
              {
                fields: {
                  answer: validator.escape(Answer),
                  student: [student],
                  assessment: [assessment],
                  submission: true,
                },
              },
            ],
            (err, records) => {
              if (err) console.error(err);
              records.forEach((record) => {
                history.push("/dashboard");
                dispatch({
                  type: "snackbaropen",
                  val_open: true,
                  val_type: "success",
                  val_message: "Thank you for submitting the assessment",
                });
                dispatch({ type: "backdropclose" });
              });
            }
          );
        }
      }
    }
  };

  return (
    <Container>
      <Box m={5}>
        <>
          <Grid>
            <Typography variant="h4" style={{ color: "#f50057" }}>
              {Assessment["name"]}
            </Typography>
          </Grid>
          <Paper>
            <Box p={3} my={2}>
              <form onSubmit={HandleSubmit}>
                <div className="HTMLText" dangerouslySetInnerHTML={createMarkup(Assessment["question"])} />
                <br />
                <TextField
                  id="outlined-multiline-static"
                  fullWidth
                  label="Please answer here"
                  multiline
                  error={ErrorAnswer}
                  rows={12}
                  value={Answer}
                  variant="outlined"
                  helperText="Please enter the answer of your assessment in the box above"
                  onBlur={(e) => {
                    setWordCount(Answer.split(/\s+/).length - 1);
                  }}
                  onChange={(e) => {
                    setAnswer(e.target.value);
                    setWordCount(Answer.split(/\s+/).length - 1);
                  }}
                />
                <Typography variant="subtitle2" display="block" align="right">
                  Word Count: {WordCount}
                </Typography>
                <Button variant="contained" size="large" color="secondary" type="submit">
                  Submit answer
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button variant="outlined" size="large" color="secondary" onClick={HandleSave}>
                  Save answer
                </Button>
                &nbsp;&nbsp;&nbsp;
              </form>
            </Box>
          </Paper>
        </>
      </Box>
    </Container>
  );
};
