import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, TableContainer, TableRow, TableCell, Container, Typography, Paper, Table, TableHead, TableBody, Button } from "@material-ui/core";

import { v4 } from "uuid";

import Moment from "moment";
import MomentTz from "moment-timezone";

import AirtableBase from "../modules/airtable";
import configurations from "../modules/configurations";

export default () => {
  const { dispatch } = useContext(configurations);
  const history = useHistory();
  const Available = localStorage.getItem("isLoggedIn");

  const [Student, setStudent] = useState({});

  const [Schedule, setSchedule] = useState([]);
  const [GroupName, setGroupName] = useState("");

  useEffect(() => {
    if (!Available) history.push("/");
  });

  useEffect(() => {
    const student = localStorage.getItem("student");
    if (student) {
      setStudent(JSON.parse(student));
    } else {
      history.push("/");
    }
  }, [history]);

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    const schedule = [];
    AirtableBase("schedule")
      .select({
        sort: [{ field: "name", direction: "asc" }],
      })
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach((record) => {
            if ("pqp group" in record.fields) {
              const all = record.get("pqp group");
              const search = Student["pqp group"][0];
              if (all.includes(search)) {
                setGroupName(record.fields["group name"]);
                record.fields.id = record.id;
                schedule.push(record.fields);
              }
            }
          });
          fetchNextPage();
        },
        (err) => {
          if (err) console.error(err);
          setSchedule(schedule);
          dispatch({ type: "backdropclose" });
        }
      );
  }, [Student, dispatch]);

  const HandleAssessmentButton = ({ row, student }) => {
    const history = useHistory();
    const [Assessments, setAssessments] = useState([]);

    useEffect(() => {
      const assessments = row.assessments;
      if (assessments) {
        let formula = "";
        assessments.forEach((row, index) => {
          formula += `RECORD_ID() = '${row}'`;
          formula += index + 1 < assessments.length ? ", " : "";
        });
        const assessmentArray = [];
        AirtableBase("assessments")
          .select({
            filterByFormula: `AND({available}, OR(${formula}))`,
            //filterByFormula: `OR(${formula})`,
          })
          .eachPage(
            (records, fetchNextPage) => {
              records.forEach((record) => {
                record.fields.id = record.id;
                assessmentArray.push(record.fields);
              });
              fetchNextPage();
            },
            (err) => {
              if (err) console.error(err);
              setAssessments(assessmentArray);
            }
          );
      }
    }, []);

    const HandleAssessment = (data) => {
      history.push(`/assessment/${data.type[0]}/${data.id}/${student.id}`);
    };

    return (
      <>
        {Assessments.map((data, key) => {
          return (
            <Box m={1} key={key} display="inline">
              <Button
                variant="contained"
                disableElevation
                color="secondary"
                onClick={() => {
                  HandleAssessment(data);
                }}
              >
                {data.place[0]}
              </Button>
            </Box>
          );
        })}
      </>
    );
  };

  const HandleDateTime = ({ row, student }) => {
    var now = MomentTz.tz("Europe/London");
    var date = MomentTz.tz(row.date + " " + row.time, "Europe/London");
    let disabled = true;

    if (date.diff(now, "minutes") >= -120 && date.diff(now, "minutes") <= 5) {
      disabled = false;
    }

    const HandleZoom = (row) => {
      dispatch({ type: "backdropopen" });
      AirtableBase("pqp attendance").create(
        [
          {
            fields: {
              uuid: v4(),
              student: [student.id],
              schedule: [row.id],
            },
          },
        ],
        (err, records) => {
          if (err) console.error(err);
          records.forEach((record) => {
            window.location.href = row["zoom link"];
          });
        }
      );
    };

    return (
      <Button
        variant="contained"
        disableElevation
        disabled={disabled}
        color="secondary"
        onClick={() => {
          HandleZoom(row);
        }}
      >
        Join Lecture
      </Button>
    );
  };

  return (
    <Container>
      <Box m={5}>
        <>
          <Grid>
            <Paper>
              <Box p={2} my={2}>
                <Typography variant="h6">
                  Schedule for the Pre-Qualifying classes for group{" "}
                  <span style={{ color: "#f50057" }}>
                    <strong>{GroupName}</strong>
                  </span>
                </Typography>
                <br></br>
                <Typography variant="body1" display="block" gutterBottom style={{ color: "#f50057" }}>
                  The LINK TO JOIN THE CLASS will be active 5 minutes before the lecture.
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  (Please refresh the page if you are unable to see the link 5 minutes before the class)
                </Typography>
              </Box>
            </Paper>

            <TableContainer component={Paper} style={{ fontSize: "18px" }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Session</TableCell>
                    <TableCell>Day</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Time</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Lecturer name</TableCell>
                    <TableCell>Join class</TableCell>
                    <TableCell>Assessments</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Schedule.map((row, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        <strong>{row.name}</strong>
                      </TableCell>
                      <TableCell scope="row">{Moment(row.date).format("dddd")}</TableCell>
                      <TableCell scope="row">
                        <strong>{Moment(row.date).format("Do MMMM YYYY")}</strong>
                      </TableCell>
                      <TableCell>{row.time}</TableCell>
                      <TableCell>{row.duration / 3600} hours</TableCell>
                      <TableCell>{row["lecturer name"][0]}</TableCell>
                      <TableCell>
                        <HandleDateTime row={row} student={Student}></HandleDateTime>
                      </TableCell>
                      <TableCell>
                        <HandleAssessmentButton row={row} student={Student}></HandleAssessmentButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      </Box>
    </Container>
  );
};
