import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import Moment from "moment";

import AirtableBase from "../modules/airtable";
import { Container, Grid, Paper, Typography, Box, TextField, Button, Input } from "@material-ui/core";
import configurations from "../modules/configurations";

import validator from "validator";

export default () => {
  const history = useHistory();
  const { dispatch } = useContext(configurations);
  const Available = localStorage.getItem("isLoggedIn");

  const [Saved, setSaved] = useState(false);
  const [SaveId, setSaveId] = useState("");

  const [Assessment, setAssessment] = useState({});

  const { assessment, student } = useParams();

  const [Answer, setAnswer] = useState("");
  const [ErrorAnswer, setErrorAnswer] = useState(false);

  useEffect(() => {
    if (!Available) history.push("/");
  }, [Available, history]);

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("assessments").find(assessment, function (err, record) {
      if (err) console.error(err);
      setAssessment(record.fields);
      dispatch({ type: "backdropclose" });
    });
  }, [assessment, dispatch]);

  const createMarkup = (html) => {
    return { __html: html };
  };

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("solutions")
      .select({ maxRecords: 1, filterByFormula: `AND({student id}='${student}', {assessment id}='${assessment}')` })
      .firstPage((err, records) => {
        const student = records[0].fields;
        if ("submission" in student) {
          dispatch({
            type: "snackbaropen",
            val_open: true,
            val_type: "error",
            val_message: "You have already submitted the assessment",
          });
          history.push("/dashboard");
        } else {
          const ans = student.answer;
          setAnswer(validator.unescape(ans));
          setSaved(true);
          setSaveId(records[0].id);
        }
        dispatch({ type: "backdropclose" });
      });
  }, [assessment, student, dispatch, history]);

  const HandleUpload = async (file, url, id) => {
    const formData = new FormData();
    formData.append("avatar", file);
    formData.append("url", url);
    formData.append("id", id);
    return await axios.post("https://www.lsclondon.co.uk/api/upload.php", formData, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  const HandleSave = (event) => {
    dispatch({ type: "backdropopen" });

    if (Saved === true) {
      AirtableBase("solutions").update(
        [
          {
            id: SaveId,
            fields: {
              answer: validator.escape(Answer),
            },
          },
        ],
        function (err, records) {
          if (err) console.error(err);
          dispatch({
            type: "snackbaropen",
            val_open: true,
            val_type: "success",
            val_message: "Your answer is saved in the system",
          });
          dispatch({ type: "backdropclose" });
        }
      );
    }

    if (Saved === false) {
      AirtableBase("solutions").create(
        [
          {
            fields: {
              answer: validator.escape(Answer),
              student: [student],
              assessment: [assessment],
            },
          },
        ],
        (err, records) => {
          if (err) console.error(err);
          records.forEach((record) => {
            setSaved(true);
            setSaveId(record.id);
            dispatch({
              type: "snackbaropen",
              val_open: true,
              val_type: "success",
              val_message: "Your answer is saved in the system",
            });
            dispatch({ type: "backdropclose" });
          });
        }
      );
    }
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();

    if (Answer === "") {
      setErrorAnswer(true);
    } else {
      if (
        Answer.type === "application/vnd.ms-powerpoint" ||
        Answer.type === "application/vnd.ms-powerpoint" ||
        Answer.type === "application/pdf" ||
        Answer.type === "application/vnd.openxmlformats-officedocument.presentationml.slideshow" ||
        Answer.type === "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        const confirm = window.confirm("Are you sure you want to submit. You will not be able to submit your answer again.");

        if (confirm) {
          dispatch({ type: "backdropopen" });
          const fileName = Moment().format("x");
          const file = "https://www.lsclondon.co.uk/api/uploads/pqp/" + fileName + "." + Answer.name.split(".").pop();
          await HandleUpload(Answer, "pqp", fileName);

          if (Saved === true) {
            AirtableBase("solutions").update(
              [
                {
                  id: SaveId,
                  fields: {
                    answer: file,
                    submission: true,
                  },
                },
              ],
              function (err, records) {
                if (err) console.error(err);
                history.push("/dashboard");
                dispatch({
                  type: "snackbaropen",
                  val_open: true,
                  val_type: "success",
                  val_message: "Thank you for submitting the assessment",
                });
                dispatch({ type: "backdropclose" });
              }
            );
          }

          if (Saved === false) {
            AirtableBase("solutions").create(
              [
                {
                  fields: {
                    answer: file,
                    student: [student],
                    assessment: [assessment],
                    submission: true,
                  },
                },
              ],
              (err, records) => {
                if (err) console.error(err);
                records.forEach((record) => {
                  history.push("/dashboard");
                  dispatch({
                    type: "snackbaropen",
                    val_open: true,
                    val_type: "success",
                    val_message: "Thank you for submitting the assessment",
                  });
                  dispatch({ type: "backdropclose" });
                });
              }
            );
          }
        }
      } else {
        setErrorAnswer(true);
      }
    }
  };

  return (
    <Container>
      <Box m={5}>
        <>
          <Grid>
            <Typography variant="h4" style={{ color: "#f50057" }}>
              {Assessment["name"]}
            </Typography>
          </Grid>
          <Paper>
            <Box p={3} my={2}>
              <form onSubmit={HandleSubmit}>
                <div className="HTMLText" dangerouslySetInnerHTML={createMarkup(Assessment["question"])} />
                <br />
                <Paper>
                  <Box p={2}>
                    <Typography variant="subtitle1">Please upload your pdf/ppt file</Typography>
                    <br></br>
                    <input
                      type="file"
                      onChange={(e) => {
                        setAnswer(e.target.files[0]);
                      }}
                    ></input>
                    <br></br>
                    <br></br>
                    {ErrorAnswer ? (
                      <Typography color="secondary" variant="subtitle1">
                        Please select a valid ppt or pdf file. No other file format will be accepted.
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Paper>
                <br />
                <br />
                <Button variant="contained" size="large" color="secondary" type="submit">
                  Submit file
                </Button>
              </form>
            </Box>
          </Paper>
        </>
      </Box>
    </Container>
  );
};
