import React, { useState, useContext, useEffect } from "react";
import { Grid, Typography, Box, TextField, Button } from "@material-ui/core";
import Validator from "validator";
import { useHistory } from "react-router-dom";

import AirtableBase from "../modules/airtable";
import configurations from "../modules/configurations";
import lsclondon from "../assets/lsclondon.svg";
import validator from "validator";

export default () => {
  const { dispatch } = useContext(configurations);
  const [User, setUser] = useState({
    email: "",
    phone: "",
  });

  const [UserError, setUserError] = useState({
    email: "",
    phone: "",
  });

  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "backdropclose" });
  }, [dispatch]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const HandleValidate = (field, form) => {
    let flag = true;

    if (field === "email" || form) {
      if (Validator.isEmpty(User.email)) {
        HandleSetState(setUserError, "email", "Please provide your email address");
        flag = false;
      } else if (!Validator.isEmail(User.email)) {
        HandleSetState(setUserError, "email", "Please provide a valid email address");
        flag = false;
      } else {
        HandleSetState(setUserError, "email", "");
        HandleSetState(setUser, "email", validator.normalizeEmail(validator.trim(User.email), { gmail_remove_dots: false }));
      }
    }

    if (field === "phone" || form) {
      if (Validator.isEmpty(User.phone)) {
        HandleSetState(setUserError, "phone", "Please provide your phone number without a 0");
        flag = false;
      } else if (!validator.isLength(User.phone, { max: 10, min: 10 }) || !validator.isNumeric(User.phone)) {
        HandleSetState(setUserError, "phone", "Please enter a valid phone number of 10 digits without a 0");
        flag = false;
      } else {
        HandleSetState(setUserError, "phone", "");
        HandleSetState(setUser, "phone", validator.trim(User.phone));
      }
    }

    return flag;
  };

  const HandleSubmit = async (event) => {
    event.preventDefault();
    if (HandleValidate("", true)) {
      AirtableBase("students")
        .select({ filterByFormula: `AND({email_address}='${User.email}', {Approved by TA}, {interviewed})`, maxRecords: 1 })
        .firstPage((error, records) => {
          if (error) console.error(error);
          if (records.length === 0) {
            HandleSetState(setUserError, "email", "The email address doesn't exist in our systems");
            dispatch({
              type: "snackbaropen",
              val_open: true,
              val_type: "error",
              val_message: "The email address doesn't exist in our system. Please contact your admissions advisor.",
            });
          } else {
            const student = records[0].fields;
            student.id = records[0].id;
            if ("accepted" in student) {
              if (student.phone_number === User.phone) {
                localStorage.setItem("student", JSON.stringify(student));
                localStorage.setItem("isLoggedIn", true);
                history.push("/dashboard");
              } else {
                HandleSetState(setUserError, "phone", "The phone number doesnot exist in the system");
              }
            } else {
              dispatch({
                type: "snackbaropen",
                val_open: true,
                val_type: "error",
                val_message: "You are not a verified user. Please verify your account.",
              });
              history.push(`/verify/${records[0].id}`);
            }
          }
        });
    }
  };

  const HandleChange = (event) => {
    HandleSetState(setUser, event.target.name, event.target.value);
  };

  const HandleBlur = (event) => {
    HandleSetState(setUser, event.target.name, event.target.value);
    HandleValidate(event.target.name);
  };

  const HandleSetState = (setState, key, value) => {
    setState((data) => ({ ...data, [key]: value }));
  };

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }} className="IndexContainer">
      <img src={lsclondon} alt="London School of Commerce Logo" width="120"></img>
      <br />
      <Typography variant="overline">Welcome to the portal of</Typography>
      <Typography variant="h5">London School of Commerce</Typography>
      <br />
      <Typography variant="h6">Please login with your email address and phone number</Typography>
      <br />
      <br />
      <form autoComplete="off" onSubmit={HandleSubmit}>
        <Box>
          <TextField
            error={UserError.email ? true : false}
            name="email"
            id="email"
            fullWidth
            label="Email address"
            variant="outlined"
            style={{ width: "300px" }}
            value={User.email}
            onChange={HandleChange}
            onBlur={HandleBlur}
            helperText={UserError.email}
          />
        </Box>
        <br />
        <Box>
          <TextField
            error={UserError.phone ? true : false}
            fullWidth
            name="phone"
            id="phone"
            label="Mobile number"
            variant="outlined"
            style={{ width: "300px" }}
            value={User.phone}
            onChange={HandleChange}
            onBlur={HandleBlur}
            helperText={UserError.phone}
          />
        </Box>
        <Box my={3}>
          <Button type="submit" disableElevation variant="contained" size="large" color="secondary" fullWidth>
            Submit
          </Button>
        </Box>
      </form>
    </Grid>
  );
};
