import React, { useEffect } from "react";
import { AppBar, Toolbar, Typography, Button, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default () => {
  const history = useHistory();
  const User = JSON.parse(localStorage.getItem("student"));
  const Available = localStorage.getItem("isLoggedIn");

  const email = `mailto:${User["partner email"]}`;

  useEffect(() => {
    if (!Available) history.push("/");
  });

  return (
    <AppBar position="static" color="secondary">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Hi {User["first name"]} {User["last name"]}, Welcome to the Pre-Qualifying Portal
        </Typography>
        <Box mx={1}>
          <Button color="secondary" variant="contained" disableElevation onClick={() => history.push("/dashboard")}>
            home
          </Button>
        </Box>
        <Box mx={1}>
          <Button color="secondary" variant="contained" disableElevation href={email}>
            Write to us
          </Button>
        </Box>
        <Box>
          <Button color="secondary" variant="contained" disableElevation onClick={() => history.push("/")}>
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
