import React, { useState, useContext } from "react";
import { Grid, Typography, Box, TextField, Button } from "@material-ui/core";
import validator from "validator";
import { useHistory } from "react-router-dom";

import AirtableBase from "../modules/airtable";
import configurations from "../modules/configurations";
import lsclondon from "../assets/lsclondon.svg";

export default () => {
  const { dispatch } = useContext(configurations);
  const [Email, setEmail] = useState("");
  const [EmailError, setEmailError] = useState(false);
  const history = useHistory();

  const HandleSubmit = (event) => {
    event.preventDefault();
    if (Email === "") {
      setEmailError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide an email address" });
    } else if (!validator.isEmail(Email)) {
      setEmailError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please enter a valid email address" });
    } else {
      setEmailError(false);
      dispatch({ type: "backdropopen" });
      setEmail(validator.normalizeEmail(validator.trim(Email), { gmail_remove_dots: false }));
      AirtableBase("students")
        .select({ filterByFormula: `AND({email_address}='${Email}', {Approved by TA}, {interviewed})`, maxRecords: 1 })
        .firstPage((error, records) => {
          if (error) console.error(error);
          if (records.length === 0) {
            setEmailError(true);
            dispatch({
              type: "snackbaropen",
              val_open: true,
              val_type: "error",
              val_message: "The email address doesn't exist in our system. Please contact your admissions advisor.",
            });
          } else {
            records.forEach((record) => {
              if (record.fields.accepted) {
                dispatch({
                  type: "snackbaropen",
                  val_open: true,
                  val_type: "success",
                  val_message: "You are a verified user. Please Login with your email address and phone number.",
                });
                history.push(`/`);
              } else {
                history.push(`/verify/${record.id}`);
              }
              dispatch({ type: "backdropclose" });
            });
          }
        });
    }
  };

  return (
    <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }} className="IndexContainer">
      <img src={lsclondon} alt="London School of Commerce Logo" width="120"></img>
      <br />
      <Typography variant="overline">Welcome to the portal of</Typography>
      <Typography variant="h5">London School of Commerce</Typography>
      <br />
      <Typography variant="h6">Please verify your account</Typography>
      <br />
      <Typography variant="subtitle1">Please enter your email address</Typography>
      <br />
      <form autoComplete="off" onSubmit={HandleSubmit}>
        <Box>
          <TextField
            error={EmailError}
            fullWidth
            id="email"
            name="email"
            label="Email address"
            variant="outlined"
            style={{ width: "300px" }}
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Box>
        <Box my={3}>
          <Button type="submit" disableElevation variant="contained" size="large" color="secondary" fullWidth>
            Submit
          </Button>
        </Box>
      </form>
    </Grid>
  );
};
