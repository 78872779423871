import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  Paper,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import Moment from "moment";

import AirtableBase from "../modules/airtable";
import lsclondon from "../assets/lsclondon.svg";
import { useParams, useHistory } from "react-router-dom";
import configurations from "../modules/configurations";

export default () => {
  const { dispatch } = useContext(configurations);
  const { id } = useParams();
  const history = useHistory();
  const [Student, setStudent] = useState({});
  const [StudentError, setStudentError] = useState(false);

  const [Result, setResult] = useState("confirm");
  const [Reason, setReason] = useState("");
  const [ReasonError, setReasonError] = useState(false);

  const [Schedule, setSchedule] = useState([]);
  const [GroupName, setGroupName] = useState("");

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        if (record.fields.rejected) {
          setStudentError(true);
        } else {
          if (record.fields["Approved by TA"] && record.fields["feedback"] === "Approved" && record.fields["interviewed"]) {
            setStudent(record.fields);
            setStudentError(false);
          }
        }
      } else {
        setStudentError(true);
      }
      dispatch({ type: "backdropclose" });
    });
  }, [id, dispatch]);

  useEffect(() => {
    if (!StudentError) {
      dispatch({ type: "backdropopen" });
      const schedule = [];
      AirtableBase("schedule")
        .select({
          sort: [{ field: "name", direction: "asc" }],
        })
        .eachPage(
          (records, fetchNextPage) => {
            records.forEach((record) => {
              if ("pqp group" in record.fields) {
                const all = record.get("pqp group");
                const search = Student["pqp group"][0];
                if (all.includes(search)) {
                  setGroupName(record.fields["group name"]);
                  schedule.push(record.fields);
                }
              }
            });
            fetchNextPage();
          },
          (err) => {
            if (err) console.error(err);
            setSchedule(schedule);
            dispatch({ type: "backdropclose" });
          }
        );
    }
  }, [Student, StudentError, dispatch]);

  const HandleSubmit = () => {
    if (Result === "confirm") {
      history.push(`/phone/${id}`);
    }
    if (Result === "reject") {
      if (Reason === "") {
        setReasonError(true);
        dispatch({
          type: "snackbaropen",
          val_open: true,
          val_type: "error",
          val_message: "Please provide a valid reason.",
        });
      } else {
        AirtableBase("students").update(
          [
            {
              id: id,
              fields: {
                student_pqp_comments: Reason,
                rejected: true,
              },
            },
          ],
          (err, records) => {
            if (err) console.error(err);
            records.forEach((record) => {
              setStudentError(true);
            });
          }
        );
      }
    }
  };

  return (
    <Container>
      <Box m={5}>
        {StudentError ? (
          <Grid container spacing={3} justify="center" alignItems="center">
            <Typography variant="h6">Please contact admissions for more information</Typography>
          </Grid>
        ) : (
          <>
            <Grid container spacing={3} justify="flex-end" alignItems="center">
              <Grid item xs={4}>
                <img src={lsclondon} width="150" alt="London School of Commerce Logo"></img>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4">Welcome to the Pre-Qualifying Portal</Typography>
              </Grid>
            </Grid>
            <Grid>
              <Box py={4}>
                <Typography variant="h6">
                  Welcome{" "}
                  <strong>
                    {Student["first name"]} {Student["last name"]}
                  </strong>
                  ,<br />
                  Below is your complete schedule for the Pre-Qualifying classes for group <strong>{GroupName}</strong>.<br />
                </Typography>
              </Box>

              <TableContainer component={Paper} style={{ fontSize: "18px" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Session</TableCell>
                      <TableCell>Day</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Lecturer name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Schedule.map((row, key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <strong>{row.name}</strong>
                        </TableCell>
                        <TableCell scope="row">{Moment(row.date).format("dddd")}</TableCell>
                        <TableCell scope="row">
                          <strong>{Moment(row.date).format("Do MMMM YYYY")}</strong>
                        </TableCell>
                        <TableCell>{row.time}</TableCell>
                        <TableCell>{row.duration / 3600} hours</TableCell>
                        <TableCell>{row["lecturer name"][0]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box py={2}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="result" name="result" value={Result} onChange={(e) => setResult(e.target.value)}>
                    <FormControlLabel value="confirm" control={<Radio />} label="I Confirm" />
                    {/*<FormControlLabel value="reject" control={<Radio />} label="I am not able to join" />*/}
                  </RadioGroup>
                </FormControl>
              </Box>
              {Result === "reject" ? (
                <Box py={2}>
                  <TextField
                    label="Why are you unable to join the PQP class accorinding to the schedule above?"
                    variant="outlined"
                    error={ReasonError}
                    fullWidth
                    onChange={(e) => setReason(e.target.value)}
                    value={Reason}
                  />
                </Box>
              ) : (
                ""
              )}
              <Box py={2}>
                <Button variant="contained" color="secondary" onClick={HandleSubmit}>
                  Submit
                </Button>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};
