import React, { useReducer } from "react";
import "./scss/app.scss";

import { HashRouter, Switch, Route } from "react-router-dom";
import { Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import Header from "./components/Header";
import Verify from "./components/Verify";
import Email from "./components/Email";
import Phone from "./components/Phone";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import WritingAssessment from "./components/WritingAssessment";
import FileUploadAssessment from "./components/FileUploadAssessment";

import configurations from "./modules/configurations";

const initialState = {
  backdrop: false,
  val_message: "",
  val_type: "",
  val_open: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "backdropopen":
      return { ...state, backdrop: true };
    case "backdropclose":
      return { ...state, backdrop: false };
    case "snackbarclose":
      return { ...state, val_open: false };
    case "snackbaropen":
      return { ...state, val_open: true, val_type: action.val_type, val_message: action.val_message };
    default:
      return state;
  }
};

const Configurations = configurations;
function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const Available = localStorage.getItem("isLoggedIn");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "snackbarclose" });
  };

  return (
    <Configurations.Provider value={{ state, dispatch }}>
      <Backdrop open={state.backdrop} style={{ zIndex: "999999" }}>
        <CircularProgress></CircularProgress>
      </Backdrop>
      <HashRouter>
        {Available ? <Header></Header> : ""}
        <Switch>
          <Route path="/verify/:id">
            <Verify></Verify>
          </Route>
          <Route path="/email/">
            <Email></Email>
          </Route>
          <Route path="/phone/:id">
            <Phone></Phone>
          </Route>
          <Route path="/" exact>
            <Login></Login>
          </Route>
          <Route path="/dashboard" exact>
            <Dashboard></Dashboard>
          </Route>
          <Route path="/assessment/writing/:assessment/:student" exact>
            <WritingAssessment></WritingAssessment>
          </Route>
          <Route path="/assessment/file-upload/:assessment/:student" exact>
            <FileUploadAssessment></FileUploadAssessment>
          </Route>
        </Switch>

        <Snackbar open={state.val_open} autoHideDuration={10000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={state.val_type}>
            {state.val_message}
          </Alert>
        </Snackbar>
      </HashRouter>
    </Configurations.Provider>
  );
}

export default App;
