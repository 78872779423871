import React, { useState, useContext, useEffect } from "react";
import { Grid, Typography, Box, TextField, Button } from "@material-ui/core";
import validator from "validator";
import { useHistory, useParams } from "react-router-dom";

import AirtableBase from "../modules/airtable";
import configurations from "../modules/configurations";
import lsclondon from "../assets/lsclondon.svg";

export default () => {
  const { dispatch } = useContext(configurations);
  const { id } = useParams();
  const [Student, setStudent] = useState({});
  const [StudentError, setStudentError] = useState({});
  const [Phone, setPhone] = useState("");
  const [PhoneError, setPhoneError] = useState(false);
  const [Confirm, setConfirm] = useState("");
  const [ConfirmError, setConfirmError] = useState(false);

  const history = useHistory();

  useEffect(() => {
    dispatch({ type: "backdropopen" });
    AirtableBase("students").find(id, function (err, record) {
      if (err) console.error(err);
      if (record) {
        if (record.fields["Approved by TA"] && record.fields["feedback"] === "Approved" && record.fields["interviewed"]) {
          setStudent(record.fields);
          setStudentError(false);
        }
      } else {
        setStudentError(true);
      }
      dispatch({ type: "backdropclose" });
    });
  }, [id, dispatch]);

  const HandleSubmit = (event) => {
    event.preventDefault();
    if (Phone === "" && Confirm === "") {
      setPhoneError(true);
      setConfirmError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide your phone number" });
    } else if (Phone === "") {
      setPhoneError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide your phone number" });
    } else if (Confirm === "") {
      setConfirmError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide your phone number" });
    } else if (!validator.isNumeric(Phone) || !validator.isNumeric(Confirm)) {
      setPhoneError(true);
      setConfirmError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide a valid 10 digit mobile phone number" });
    } else if (!validator.isLength(Phone, { max: 10, min: 10 }) || !validator.isLength(Confirm, { max: 10, min: 10 })) {
      setPhoneError(true);
      setConfirmError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "Please provide a valid 10 digit mobile phone number" });
    } else if (Phone !== Confirm) {
      setPhoneError(true);
      setConfirmError(true);
      dispatch({ type: "snackbaropen", val_open: true, val_type: "error", val_message: "The phone number and confirm phone number donot match" });
    } else {
      setPhoneError(false);
      setConfirmError(false);
      dispatch({ type: "backdropopen" });
      AirtableBase("students").update(
        [
          {
            id: id,
            fields: {
              phone_number: Phone,
              accepted: true,
              accepted_on: new Date(),
            },
          },
        ],
        (err, records) => {
          if (err) console.error(err);
          records.forEach((record) => {
            console.log(record);
            record.fields.id = id;
            localStorage.setItem("student", JSON.stringify(record.fields));
            localStorage.setItem("isLoggedIn", true);
            history.push("/dashboard");
          });
        }
      );
    }
  };

  return (
    <>
      {StudentError ? (
        <Box m={5}>
          <Grid container spacing={3} justify="center" alignItems="center">
            <Typography variant="h6">Please contact your admissions advisor.</Typography>
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: "100vh" }} className="IndexContainer">
          <img src={lsclondon} alt="London School of Commerce Logo" width="120"></img>
          <br />
          <Typography variant="overline">Welcome to the portal of</Typography>
          <Typography variant="h5">London School of Commerce</Typography>
          <br />
          <Typography variant="h6">
            <span style={{ color: "red" }}>To complete your acceptance, Please register your phone number below.</span>
          </Typography>
          <br />
          <Typography variant="subtitle1" align="center">
            Your email address is <strong>{Student["email_address"]}.</strong>
            <br />
          </Typography>
          <br />
          <br />
          <form autoComplete="off" onSubmit={HandleSubmit}>
            <Box>
              <TextField
                error={PhoneError}
                fullWidth
                label="Phone number"
                variant="outlined"
                style={{ width: "300px" }}
                value={Phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Box>
            <br />
            <Box>
              <TextField
                error={ConfirmError}
                fullWidth
                label="Confirm phone number"
                variant="outlined"
                style={{ width: "300px" }}
                value={Confirm}
                onChange={(e) => setConfirm(e.target.value)}
              />
            </Box>
            <Box my={3}>
              <Button type="submit" disableElevation variant="contained" size="large" color="secondary" fullWidth>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
      )}
    </>
  );
};
